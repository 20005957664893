body,
input {
  margin: 0;
  font-family: 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Roboto',
    'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page-wrapper {
  margin-left: 15rem;
  margin-right: 15rem;
}

h1,
h2,
h3,
h4 {
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-weight: normal;
}

/* Mulig løsning på font-problemet
/* p,
div {
  font-weight: 200;
} */
